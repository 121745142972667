<template>
  <b-modal
      ref="change-email-modal"
      size="lg"
      title="Change email"
  >
    <b-form-group label="New email">
      <b-form-input
          v-model="form.email"
          type="text"
          placeholder=""
          required
          :state="!$v.form.email.$dirty ? null :(!$v.form.email.$error)"
      ></b-form-input>

    </b-form-group>
    <template #modal-footer>
      <div class="w-100">
        <div class="buttons float-right">
          <b-button type="button" variant="secondary" class="mr-1" @click="hideModal()">{{ $t('btn.cancel') }}
          </b-button>
          <b-button variant="primary" :disabled="busy" @click="submit()">
            <div v-if="busy">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('btn.state.saving') }}
            </div>
            <div v-else>
              {{ $t('btn.save') }}
            </div>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import {email, required} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      busy: false,
      title: null,
      groups: [],
      serverError: null,
      form: {
        email: null
      },
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
    }
  },
  mounted() {
    this.openModal();
  },
  computed: {},
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.dispatch('clientsStore/changeEmail', {...this.form, id: this.$parent.clientId})
            .then(() => {
              this.hideModal();
              setTimeout(() => {
                this.$store.dispatch('clientsStore/getClient', this.$parent.clientId);
                this.$emit('reload-items')
              }, 500)
            })
      } else {
        console.log('invalid input: ' + this.$v.$invalid);
      }
    },
    openModal() {
      this.$refs['change-email-modal'].show();
    },
    hideModal() {
      this.$refs['change-email-modal'].hide();
    },
  }
}
</script>
