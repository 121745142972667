<template>
  <b-modal
      ref="add-coins-modal"
      size="lg"
      title="Wallet gold manual correction"
  >
    <b-form-group label="Gold amount">
      <b-form-input
          v-model="form.correction"
          type="number"
          step="0.0001"
          placeholder="main amount"
          required
          :state="!$v.form.correction.$dirty ? null :(!$v.form.correction.$error)"
      ></b-form-input>

      <b-form-group label="Description">
        <b-form-input
            v-model="form.description"
            placeholder="description"
            required
            :state="!$v.form.description.$dirty ? null :(!$v.form.description.$error)"
        ></b-form-input>
      </b-form-group>

    </b-form-group>
    <template #modal-footer>
      <div class="w-100">
        <div class="buttons float-right">
          <b-button type="button" variant="secondary" class="mr-1" @click="hideModal()">{{ $t('btn.cancel') }}
          </b-button>
          <b-button variant="primary" :disabled="busy" @click="submit()">
            <div v-if="busy">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('btn.state.saving') }}
            </div>
            <div v-else>
              {{ $t('btn.save') }}
            </div>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import {decimal, required} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      busy: false,
      title: null,
      groups: [],
      serverError: null,
      form: {
        correction: 0,
        description: null
      },
    }
  },
  validations: {
    form: {
      correction: {
        required,
        decimal
      },
      description: {
        required
      },
    }
  },
  mounted() {
    this.openModal();
  },
  computed: {},
  methods: {
    submit() {
      this.$v.$touch();
      console.log(this.$v.$invalid);
      if (!this.$v.$invalid) {
        this.$store.dispatch('clientsStore/manualGoldCorrection', {...this.form, walletId: this.$parent.walletId})
            .then(() => {
              this.hideModal();
              setTimeout(() => {
                this.$store.dispatch('clientsStore/getWallet', this.$parent.clientId);
                this.$emit('reload-items')
              }, 500)
            })
      }
    },
    openModal() {
      this.$refs['add-coins-modal'].show();
    },
    hideModal() {
      this.$refs['add-coins-modal'].hide();
    },
  }
}
</script>
