<template>
  <div>
    <div class="sygni-grid">
      <div class="row">
        <div class="col-4">
          <filter-date-range-picker
              :filter-query="filterQuery"
              :locale="{firstDay: 1, format: 'yyyy-mm-dd'}"
              :auto="true"
              :linked="false"
              :time-picker="false"
              filter-name="date"
              open-direction="left"
              label="Date">
          </filter-date-range-picker>
        </div>
        <div class="col-4">
          <filter-input
              :filter-query="filterQuery"
              filter-operator="like"
              filter-name="description"
              :label="$t('filters.label.description')"
              :placeholder="$t('filters.placeholder.description')">
          </filter-input>
        </div>
        <div class="col-4">
          <filter-select
              :filter-query="filterQuery"
              :options="filterOptions.types"
              :multiple="false"
              options-label="text"
              options-track-by="value"
              filter-name="operationType"
              label="Operation type"
              placeholder="Operation type">
          </filter-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
          <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
        </div>
      </div>
    </div>
    <div class="sygni-grid">
      <div class="row mt-2">
        <div class="col-12">
          <grid-pagination :total-count="totalCount"
                           :per-page="perPage"
                           :current-page="currentPage"
                           @load-items="paginationReload"></grid-pagination>

          <div class="table-responsive">
            <b-table striped show-empty
                     :empty-text="$t('table.no_results')"
                     hover
                     head-variant="dark"
                     outlined
                     :sort-by.sync="orderColumn"
                     :sort-desc.sync="orderDirection"
                     :no-local-sorting="true"
                     :items="items"
                     :fields="fields">

              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t('state.loading') }}</strong>
                </div>
              </template>

            </b-table>
          </div> <!-- #table-responsive-->

          <grid-pagination :total-count="totalCount"
                           :per-page="perPage"
                           :current-page="currentPage"
                           @load-items="paginationReload"></grid-pagination>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import FilterDateRangePicker from "@/components/grid/filters/FilterDateRangePicker.vue";
import GridMixin from "@/components/grid/GridMixin";
import FilterInput from "../../../components/grid/filters/FilterInput";
import FilterSelect from "../../../components/grid/filters/FilterSelect";

export default {
  mixins: [GridMixin],
  props: ['walletId'],
  components: {
    GridPagination,
    FilterDateRangePicker,
    FilterInput,
    FilterSelect
  },
  data() {
    return {
      gridGetUrl: '/client/' + this.walletId + '/wallet-history',
      orderColumn: 'date',
      orderDirection: false,
      additionalSort: '-index',
      fields: [
        {
          key: 'date', label: 'Date', sortable: true, formatter: (v) => {
            return (new Date(v)).toLocaleDateString() + ' ' + (new Date(v)).toLocaleTimeString();
          }
        },
        {key: 'description', label: 'Description', sortable: true},

        {key: 'operationType', label: 'Operation type', class: ['text-center']},
        {key: 'moneyOperationAmount', label: 'Money operation amount', class: ['text-center']},
        {key: 'goldOperationAmount', label: 'Gold operation amount', class: ['text-center']},
        {key: 'moneyBalanceAmountAfterOperation', label: 'Money balance', class: ['text-center']},
        {key: 'goldBalanceAmountAfterOperation', label: 'Gold balancer', class: ['text-center']},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Bulk actions'}
        ],
      },
      perPage: 10,
      currentPage: 1,
      filterQuery: [],
      filterOptions: {
        types: [
          {value: 'manual_correction', text: 'manual_correction'},
          {value: 'mining_pool_purchase', text: 'mining_pool_purchase'},
          {value: 'mining_pool_profit', text: 'mining_pool_profit'},
          {value: 'selling_gold', text: 'selling_gold'},
          {value: 'gold_bar_goal_bonus', text: 'gold_bar_goal_bonus'},
          {value: 'mining_pool_installment_return', text: 'mining_pool_installment_return'},
          {value: 'affiliation_profit', text: 'affiliation_profit'},
          {value: 'pool_share', text: 'pool_share'},
          {value: 'purchased_mining_pool_by_recommended_person', text: 'purchased_mining_pool_by_recommended_person'},
          {value: 'deposit', text: 'deposit'},
          {value: 'withdraw', text: 'withdraw'},
        ],
      },
    };
  },
  mounted() {

  },
  beforeDestroy() {
  },
  computed: {},
  methods: {},
  watch: {}
}
</script>
