<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.client_details') }} <span
          v-if="client">{{ client.id }} ({{ client.email }})</span></h3>
      <div class="float-right">
        <router-link :to="{'name':'clients-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <b-card v-if="client">
            <b-card-header>General information</b-card-header>
            <b-card-body>
              <label>Id</label>
              <p>{{ client.id }}</p>
              <label>Registration date</label>
              <p>{{ convertDate(client.createdAt) }}</p>
              <label>Sponsor</label>
              <p>
                {{ client.sponsor }}
                <b-button @click="changeSponsor()" size="sm" class="mr-1 float-right" variant="outline-primary">
                  Change sponsor
                </b-button>
              </p>
              <label>Email</label>
              <p>
                {{ client.email }}
                <b-button @click="changeEmail()" size="sm" class="mr-1 float-right" variant="outline-primary">
                  Change email
              </b-button>
              </p>
              <label>Username</label>
              <p>
                {{ client.username }}
                <b-button @click="changeUsername()" size="sm" class="mr-1 float-right" variant="outline-primary">
                  Change username
                </b-button>
              </p>
              <label>First name</label>
              <p>{{ client.firstName }}</p>
              <label>Last name</label>
              <p>{{ client.lastName }}</p>
              <label>Birthdate</label>
              <p>{{ convertDate(client.birthdate) }}</p>
              <label>Country</label>
              <p>{{ client.country }}</p>
              <label>2FA</label>
              <p>
                {{ client.twoFaEnabled ? 'enabled' : 'disabled' }}
                <b-button size="sm" variant="danger" v-if="client.twoFaEnabled" @click="disable2fa()">Disable</b-button>
              </p>

              <div v-if="client.status != 'blocked'">
              <b-button size="sm" class="mr-1 float-right" variant="danger" @click="block()">Block</b-button>
              </div>

              <div v-else>
                <b-button size="sm" class="mr-1 float-right" variant="danger" @click="unblock()">Unblock</b-button>
              </div>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-4">
          <b-card v-if="wallet && turnover">
            <b-card-header>
              Wallet information
<!--              <b-button @click="manualDeposit()" size="sm" class="mr-1 float-right" variant="outline-secondary">Deposit-->
<!--              </b-button>-->


            </b-card-header>
            <b-card-body>
              <label>Money balance</label>
              <p>{{ wallet.moneyBalance }} USD <b-button @click="manualMoneyCorrection()" size="sm" class="mr-1 float-right" variant="outline-primary">Manual
                correction
              </b-button></p>
              <label>Gold balance</label>
              <p>{{ wallet.goldBalance }} g <b-button @click="manualGoldCorrection()" size="sm" class="mr-1 float-right" variant="outline-primary">Manual
                correction
              </b-button></p>
              <label>Gold profit percent</label>
              <p>{{ wallet.goldProfitPercent }} %</p>

              <label>Rank</label>
              <p>{{ wallet.rank }}</p>

              <label>Digger</label>
              <p>{{ turnover.digger }} / 79000 ({{ wallet.digger ? 'yes' : 'no'}})</p>

              <label>Miner</label>
              <p>{{ turnover.miner }} / 316000 ({{ wallet.miner ? 'yes' : 'no'}})</p>

              <label>African</label>
              <p>{{ turnover.african }} / 2180000 ({{ wallet.african ? 'yes' : 'no'}})</p>

              <label>Global</label>
              <p>{{ turnover.global }} / 8720000 ({{ wallet.global ? 'yes' : 'no'}})</p>

              <label>Founder</label>
              <p>{{ wallet.founder ? 'yes' : 'no '}}</p>

            </b-card-body>
          </b-card>
          <b-card v-if="goldBarGoal">
            <b-card-header>
              Gold bar goal
            </b-card-header>
            <b-card-body>
              <label>Date started</label>
              <p>{{ convertDate(goldBarGoal.dateStart) }}</p>
              <label>Date finish</label>
              <p>{{ convertDate(goldBarGoal.dateEnd) }}</p>
              <label>Goal</label>
              <p>{{wallet.goldBalance}}g / {{ parseFloat(goldBarGoal.goal).toFixed(0) }}g</p>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-card>
            <b-card-header>Wallet history</b-card-header>
            <b-card-body>
              <WalletHistoryGrid :wallet-id="clientId" ref="wallet-history-grid"></WalletHistoryGrid>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ChangeEmailModal from "@/modules/clients/containers/ChangeEmailModal.vue";
import ChangeSponsorModal from "@/modules/clients/containers/ChangeSponsorModal.vue";
import WalletHistoryGrid from "@/modules/clients/containers/WalletHistoryGrid.vue";
import ManualMoneyCorrectionModal from "@/modules/clients/containers/ManualMoneyCorrectionModal.vue";
import ManualGoldCorrectionModal from "@/modules/clients/containers/ManualGoldCorrectionModal.vue";
// import ManualDepositModal from "@/modules/clients/containers/ManualDepositModal.vue";
import Vue from "vue";
import {convertDate} from "../../../main";
import axios from "axios";
import alertify from "alertifyjs";
import ChangeUsernameModal from "@/modules/clients/containers/ChangeUsernameModal.vue";

export default {
  props: ['apiKey'],
  components: {WalletHistoryGrid},
  data() {
    return {
      clientId: null,
    };
  },
  mounted() {
    this.getClient();
  },
  computed: {
    turnover: function () {
      return this.$store.getters["clientsStore/turnover"];
    },
    client: function () {
      return this.$store.getters["clientsStore/client"];
    },
    wallet: function () {
      return this.$store.getters["clientsStore/wallet"];
    },
    goldBarGoal: function () {
      return this.$store.getters["clientsStore/goldBarGoal"];
    },
    walletId: function () {
      return this.$store.getters["clientsStore/walletId"];
    }
  },
  beforeDestroy() {
    this.$store.commit('clientsStore/setClient', null);
    this.$store.commit('clientsStore/setWallet', null);
    this.$store.commit('clientsStore/setGoldBarGoal', null);
    this.$store.commit('clientsStore/setTurnover', null);
  },
  created() {
    this.clientId = this.$route.params.id;
  },
  methods: {
    convertDate,
    disable2fa() {
      axios.post('/client/' + this.clientId +'/disable-2fa')
          .then(() => {
            alertify.success('Disabled 2fa for client');
            this.getClient()
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    block() {
      axios.post('/client/' + this.clientId +'/block')
          .then(() => {
            alertify.success('Client was blocked');
            this.getClient()
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    unblock() {
      axios.post('/client/' + this.clientId +'/unblock')
          .then(() => {
            alertify.success('Client was unblocked');
            this.getClient()
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
    },
    getClient() {
      this.$store.dispatch('clientsStore/getClient', this.clientId);
      this.$store.dispatch('clientsStore/getWallet', this.clientId);
      this.$store.dispatch('clientsStore/getGoldBarsGoal', this.clientId);
      this.$store.dispatch('clientsStore/getTurnover', this.clientId);
    },
    changeEmail() {
      const ModalConstructor = Vue.extend(ChangeEmailModal);
      const modal = new ModalConstructor({parent: this});
      modal.$mount();
    },
    changeSponsor() {
      const ModalConstructor = Vue.extend(ChangeSponsorModal);
      const modal = new ModalConstructor({parent: this});
      modal.$mount();
    },
    changeUsername() {
      const ModalConstructor = Vue.extend(ChangeUsernameModal);
      const modal = new ModalConstructor({parent: this});
      modal.$mount();
    },
    manualMoneyCorrection() {
      const ModalConstructor = Vue.extend(ManualMoneyCorrectionModal);
      const modal = new ModalConstructor({parent: this});
      // modal.$on('reload-items', () => {
      //   this.$refs['wallet-history-grid'].loadItems();
      // });
      modal.$mount();
    },
    manualGoldCorrection() {
      const ModalConstructor = Vue.extend(ManualGoldCorrectionModal);
      const modal = new ModalConstructor({parent: this});
      // modal.$on('reload-items', () => {
      //   this.$refs['wallet-history-grid'].loadItems();
      // });
      modal.$mount();
    },
    // manualDeposit() {
    //   const ModalConstructor = Vue.extend(ManualDepositModal);
    //   const modal = new ModalConstructor({parent: this});
    //   modal.$on('reload-items', () => {
    //     this.$refs['wallet-history-grid'].loadItems();
    //   });
    //   modal.$mount();
    // }
  },
  watch: {
    $route() {
    }
  }
}
</script>
